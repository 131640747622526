var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      label: "Юзер Обм.",
      items: _vm.users,
      "item-text": (item) => item.username,
      "return-object": "",
      outlined: "",
      clearable: "",
    },
    on: { change: _vm.change },
    model: {
      value: _vm.user2,
      callback: function ($$v) {
        _vm.user2 = $$v
      },
      expression: "user2",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }