<template>

  <div class="ma-3">
    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions mt-10">
      <h3>Обменник - продажи</h3>
    </div>



    <div>
      <DatePeriodPicker caption="Период выборки" :from="from" :to="to" v-on:changed="onPerodChanged"></DatePeriodPicker>
    </div>

    <v-container fluid class="d-flex flex-wrap pa-0 mt-2 ">

      <v-card class="pa-2 mb-2 mr-2" height="70" width="200">
        <ExchangeUserComboboxMerchant  v-bind:user.sync="user" ></ExchangeUserComboboxMerchant>
      </v-card>

    </v-container>

    <div>
      <v-data-table
          dense
          :items="payouts"
          :headers="headers"
          class="elevation-1 mt-10"
          sort-by="id"
          sort-desc
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':[  50,  100,  200,  -1]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">
          <StatusCell :item="item"/>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <DateTimeCell :value="item.updatedAt"></DateTimeCell>
        </template>
        <template v-slot:item.amount="{ item }">
          <FinanceCell :value="item.amount"/>
        </template>
        <template v-slot:item.hold="{ item }">
          <FinanceCell :value="item.hold"/>
        </template>

        <template v-slot:item.owner="{ item }"> {{ item.owner }}</template>

        <template v-slot:item.user="{ item }"> {{  item.user.username }}</template>

        <template v-slot:item.actions="{ item }">

          <v-btn class="blue" v-if="item.status==='Success'  " dense text target="_blank"
                 :href="getDownloadLink(item.proof)">Скачать чек
          </v-btn>

          <v-btn class="blue" v-if="item.status==='Pending'  " dense text   @click="accept(item.id)">Взять в работу </v-btn>
          <ImagePickerDialog  v-if="item.status==='Processing'  "  caption="Выполнено" v-bind:tag="item.id"  v-on:ok="finish"  />
          <TextInputDialog v-if="item.status==='Processing'  " buttonclass="red" buttontext="Отклонить" caption="Причина" :tag="item.id" v-on:ok="del"></TextInputDialog>



          <span class="red--text" v-if="item.status==='Canceled'  ">{{ item.comment }}</span>
        </template>

      </v-data-table>
    </div>

  </div>

</template>

<script>


import gql from "graphql-tag";
import StatusCell from "../components/controls/StatusCell.vue";
import FinanceCell from "../components/controls/FinanceCell.vue";
import DateTimeCell from "../components/controls/DateTimeCell.vue";

import DatePeriodPicker from "../components/controls/DatePeriodPicker.vue";

import Snackbar from "../components/controls/Snackbar.vue";
import {getHeaders} from "../store";
import {fetcher} from "../store";
import ImagePickerDialog from "../components/dialogs/ImagePickerDialog.vue";

//const RestApiUrl = process.env.VUE_APP_REST_API_URL_MERCHANT + "/payout"
const RestApiUrl =  process.env.VUE_APP_REST_API_URL_ADMIN + "/payout"

import TextInputDialog from "../components/dialogs/TextInputDialog.vue";
import ExchangeUserComboboxMerchant from "../components/controls/ExchangeUserComboboxMerchant.vue";

//const exchange_user = process.env.VUE_APP_EXCHANGE_USER

export default {
  name: 'ExchangePayoutsUser',
  components: {
    ExchangeUserComboboxMerchant,
    ImagePickerDialog,
    DateTimeCell,
    FinanceCell,
    StatusCell,

    DatePeriodPicker,
    TextInputDialog,
    Snackbar
  },
  apollo: {
    $subscribe: {
      payouts: {
        query: gql`
subscription s($status: [String!], $from: timestamptz!, $to: timestamptz! , $user: String) {
  payouts(where: {_and: [{status: {_in: $status}}, {createdAt: {_gte: $from}},
    {createdAt: {_lte: $to}}, { user: { username: {_eq: $user}} } ]}) {
    id
    amount
    createdAt
    updatedAt
    status
    proof
    hold
    owner
    comment
    user {
      username
    }
  }
}

`,

        variables() {
          let u = this.user.username

          return {
            from: this.from,
            to: this.to,
            status: ["Pending", "Processing", "Success", "Canceled", "Hold"],
            user:u
          }
        },

        result({data}) {
          this.payouts = data.payouts;
        },


        skip() {
          return !this.user
        }


      },
    },
  },

  data: () => ({

    user:null,

    emptyItem: {
      bankname: null,
      amount: null,
      number: null,
      owner: null
    },

    from: "2024-01-01",
    to: "2024-12-31",

    filterByDatePeriod: false,
    payouts: [],

    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Статус', value: 'status'},
      {text: 'Дата', value: 'updatedAt'},
      {text: 'Cумма', value: 'amount'},
      {text: 'Списано', value: 'hold'},
      {text: 'Получатель', value: 'owner'},
      {text: 'Мерч', value: 'user'},
      {text: 'Действия', value: 'actions'},
    ],
  }),

  methods: {

    async accept(id){

      //console.log(id);

      /*
взять в работу выплату
PUT /api/admin/payout/:id
       */

      let headers = await getHeaders()

      const requestOptions = {
        method: 'PUT',
        headers: headers,
        //body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async finish(id, file){
      // console.log(id,file);

      /*

закрыть выплату
PATCH /api/admin/payout/:id    ( multipart/form-data )
req.body.status  ( может быть Success или Canceled )

если статус Success
то слать req.files.doc

если Canceled
то слать req.body.comment (string)

       */

      const formData  = new FormData();
      formData.append('status', 'Success')
      formData.append('doc', file )

      let headers = await getHeaders()
      //headers['Content-Type'] = 'multipart/form-data; boundary="----fucked boundary"'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },


    async del(id, text){

      // console.log(id, text);

      /*
закрыть выплату
PATCH /api/admin/payout/:id    ( multipart/form-data )
req.body.status  ( может быть Success или Canceled )

если статус Success
то слать req.files.doc

если Canceled
то слать req.body.comment (string)
       */

      const formData  = new FormData();
      formData.append('status', 'Canceled')
      formData.append('comment', text )

      let headers = await getHeaders()
      // headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData//JSON.stringify({status: 'Canceled', comment:text})
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },




    getDownloadLink(image) {
      // console.log(image);
      if (image === null)
        return;

      return `${process.env.VUE_APP_FILES_BASE}/proof/payout/${image.name}?type=${image.mimetype}`

    },

    onPerodChanged(val) {
      this.from = val.from
      this.to = val.to
    },


    async balance(tag, item) {
      //console.log(item);

      /*
app.post("/api/merchant/convert
req.body.amount (num) сумма в rub
      */


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              amount: item,
            })
      };

      let url = `${process.env.VUE_APP_REST_API_URL_MERCHANT}/convert`

      fetcher(this.dosnackbar, url, requestOptions)


    },

    async create(item) {
      // console.log(item);

      /*
ручное создание одной выплаты
app.post("/api/merchant/payout",
req.body.amount,  (float)
req.body.bankname, (string)
req.body.number, (string)
req.body.owner, (string)
      */


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              amount: item.amount,
              bankname: item.bankname,
              number: item.number,
              owner: item.owner,
            })
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)

    },


    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async accept1(id) {

      // console.log(id);

      /*
        api админу
  изменить выплату
  app.patch("/api/admin/payout/:id");  (multipart/form-data)
  req.body.status (string)    ( db.WITHDRAWAL_STATUSES = ["Pending", "Processing", "Success", "Canceled"]; )
  req.files.doc (multipart/form-data)
       */

      const formData = new FormData();
      formData.append('status', 'Processing')

      let headers = await getHeaders()
      //headers['Content-Type'] = 'multipart/form-data'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async del1(id) {

      //console.log(id);

      /*
   отмена
app.delete("/api/merchant/payout/:id",
       */

      let headers = await getHeaders()
      //headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        //body: JSON.stringify({status: 'Processing'})
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async upload(id, file) {
      //console.log(id, file);

      /*
создание выплат CSV файлом (пока не готова логика, но точка есть)
app.post("/api/merchant/payout/list",
req.files.doc (multipart/form-data) (только csv)
       */

      const formData = new FormData();
      formData.append('doc', file)

      let headers = await getHeaders()

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/list`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },
  },

}
</script>
